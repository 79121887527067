export const devProjects = [
    {
        id: "noteworthy",
        year: 2022,
        title: "Noteworthy",
        image: "projects/noteworthy.png",
        link: "https://noteworthynotes.herokuapp.com/",
        collab: "Solo work",
        stack: ["React.js", "Express.js", "MongoDB",],
        explanation: "Noteworthy not only transcribes your meeting, but also (1) lets you highlight and label important parts during/after meetings, (2) organizes highlights into an outline for you, (3) helps you to easily figure out the context of the highlights by showing the connection with the transcript, and (4) analyze the meeting data to keep you on track.",
        prize: "MIT Web Lab 2022 3rd Place & Innovation Prize",
        featured: true,
    },
    {
        id: "cube",
        year: 2017,
        title: "The Cube",
        image: "projects/cube.gif",
        link: "https://jaeyoon.io/cube",
        collab: "Solo work",
        stack: ["Vanilla JS",],
        explanation: "I developed this website as a final project in the Interactive Web Development course at the University of Southern California (USC). I ranked 1st place and got the highest grade (A) for the course.\n\nPlacing the navigation on a rotating cube definitely does not provide a good user experience, but my goal was just to develop something non-typical.",
        featured: true,
    },
    {
        id: "minglr",
        year: 2020,
        title: "Minglr",
        image: "projects/minglr.jpg",
        link: "https://minglr.us",
        code: "https://github.com/CCI-MIT/minglr",
        collab: "Solo work",
        stack: ["React.js", "Express.js", "MongoDB", "Socket.io", "Jitsi"],
        explanation: "Minglr supports ad hoc conversations at virtual conferences. It was deployed at two virtual conferences with over 450 total participants.",
        featured: true,
    },
    {
        id: "code",
        year: 2021,
        title: "CoDe",
        image: "projects/code.png",
        link: "https://sudo-cambridge.herokuapp.com",
        collab: "Teamwork (led a team of four)",
        stack: ["Vue.js", "Express.js", "MongoDB"],
        explanation: "CoDe is a platform where people can suggest ideas about urban design. It has a unique feature that allows the merging of two similar ideas.",
    },
    {
        id: "history",
        year: 2018,
        title: "Visualizing Korean Independence Movement",
        image: "projects/history.jpeg",
        link: "https://jaeyoon.io/infovis",
        code: "https://github.com/jyoonsong/hci-project",
        collab: "Teamwork (a team of two)",
        stack: ["D3.js",],
        explanation: "We visualized the Korean Independence Movement organizations in 1910 - 1945",
    },
    {
        id: "ravi",
        year: 2018,
        title: "Real-time Collaborative Video Chat Summarization",
        image: "projects/ravi.jpeg",
        code: "https://github.com/jyoonsong/RAVi",
        collab: "Solo work",
        stack: ["Chrome Extension",],
        explanation: "Inspired by Tilda (Zhang & Cranshaw, 2018), I developed a browser extension that helps users to tag and take notes while having a video chat conversation.",
    },
    {
        id: "samsung",
        year: 2018,
        title: "Restaurant Recommendation App",
        image: "projects/samsung.jpeg",
        collab: "Teamwork (a team of 5)",
        stack: ["React.js", "Express.js"],
        explanation: "My role in the team was to develop the front-end side.",
        prize: "Top Ten Finalist Prize at 2018 Samsung AI Hackathon",
    },
    {
        id: "who2chat",
        year: 2021,
        title: "Who2chat",
        image: "projects/who2chat.png",
        collab: "Teamwork (a team of 2)",
        stack: ["React.js", "Firebase", "Jitsi"],
        explanation: "Who2chat is the second version of Minglr. It was deployed at UIST 2021 and CSCW 2021",
    },
    {
        id: "magicspell",
        year: 2021,
        title: "A magic spell for me",
        image: "projects/img-1-small.jpeg",
        collab: "Solo work",
        stack: ["React.js"],
        explanation: "What is a magic spell in Harry Potter series that goes we",
    },
    {
        id: "solutionChat",
        year: 2019,
        title: "SolutionChat",
        link: "https://doi.org/10.1145/3313831.3376609",
        image: "projects/solutionchat.jpeg",
        collab: "Teamwork (Team of Two)",
        stack: ["Angular.dart", "HTML/CSS"],
        explanation: "My role in the team was to develop the front-end side interface",
    },
    {
        id: "soundglance",
        year: 2018,
        title: "SoundGlance",
        link: "https://doi.org/10.1145/3290607.3312865",
        code: "https://github.com/SoundGlance/tag-me",
        image: "projects/soundglance_tagging.jpg",
        collab: "Solo work",
        stack: ["Ruby on Rails"],
        explanation: "SoundGlance summarizes the glanceable cues of Web pages for screen reader users. This is a web interface we used to generate the dataset for CNN training.",
    },
    {
        id: "suggestbot",
        year: 2018,
        title: "SuggestBot",
        image: "projects/suggestbot.jpeg",
        collab: "Teamwork (Team of two)",
        stack: ["React.js", "Ruby on Rails"],
        explanation: "An interface to collect evidence of a given post from Mechanical Turk workers",
    },
    {
        id: "writing",
        year: 2021,
        title: "Story writing experiment platform",
        image: "projects/story.gif",
        collab: "Solo work",
        stack: ["React.js", "Empirica.js", "Meteor"],
        explanation: "An interface to collect stories from experimental subjects on Upwork",
    },
    {
        id: "stampcookies",
        year: 2021,
        title: "Stamp Cookies Mobile Game",
        image: "projects/cookies.png",
        link: "https://drive.google.com/file/d/1RGKYJNpZxTdZN9zPai-BIS8rZoyQAjGL/view",
        collab: "Solo work",
        stack: ["React Native"],
        explanation: "A mobile game to stamp cookies",
    },
    {
        id: "dance",
        year: 2021,
        title: "A Chrome Extension for Dance Practice",
        image: "projects/dance.jpg",
        link: "https://chrome.google.com/webstore/detail/mirror-youtube-for-dance/odmglmjpojlhploiiaemcdohkeoapakf",
        collab: "Solo work",
        stack: ["Vanilla JS", "Chrome Extension"],
        explanation: "A chrome extension for dance practice with over 300 users",
    },
    {
        id: "dobbies",
        year: 2021,
        title: "HCI & The Seven Dobbies",
        image: "projects/dobbies.png",
        link: "https://studyhci.netlify.app/",
        collab: "Solo work",
        stack: ["Gatsby", "Netlify"],
        explanation: "A website for an HCI study club",
    },
    {
        id: "ffaper",
        year: 2021,
        title: "FFA:PER",
        image: "projects/ffaper.png",
        link: "https://ffaper.com/",
        collab: "Solo work (Freelancer contract via Kmong)",
        stack: ["Ruby on Rails"],
        explanation: "A website for a clothing brand",
    },
    {
        id: "psnm",
        year: 2021,
        title: "SKT PS&M Event Website",
        image: "projects/psnm.png",
        link: "https://www.tworldevent.com",
        collab: "Solo work (Freelancer contract via Kmong)",
        stack: ["Ruby on Rails"],
        explanation: "A website for an event held by SKT PS&M",
    },
    {
        id: "covidhere",
        year: 2021,
        title: "Covidhere",
        image: "projects/covidhere.png",
        link: "http://covidhere.herokuapp.com/2/1",
        collab: "Solo work (Freelancer contract via Kmong)",
        stack: ["Ruby on Rails"],
        explanation: "A website that shows Covid information at Yongin-si, South Korea",
    },
    {
        id: "chartmetric",
        year: 2017,
        title: "Intern at Chartmetric (a startup)",
        image: "projects/chartmetric.jpeg",
        link: "http://chartmetric.io",
        collab: "Internship",
        stack: ["Angular.js", "Highcharts.js"],
        explanation: "As a short-term intern, I worked on enhancing the data visualization part using Highcharts.js and Angular.js",
    },
    {
        id: "truth101",
        year: 2017,
        title: "Truth 101",
        image: "projects/truth101.png",
        link: "http://truth-101.github.io",
        collab: "Teamwork (Led a team of four)",
        stack: ["D3.js"],
        explanation: "",
    },


]